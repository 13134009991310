<template>
  <div class="col-12 card card-body">
    <div style='text-align: center;'>
        <h3>
            تقرير إحصائيات الطلاب
        </h3>
    </div>
    <div class="form-group dnone2 col-12 col-lg-6 c"><br><br>
      <label for="">اختر صف معين</label>
      <select class="form-control" v-model="custom_classname" @change="gg()">
        <option :value="false">الكل</option>
        <option v-for="c in classrooms" :key="c">{{ c }}</option>
      </select><br><div class='col-12 text-center'><button class='btn btn-primary' @click="print()">طباعة الصفحة</button></div><br>
    </div>
    <table>
        <thead>
            <th>الإسم</th>
            <th>الفصل</th>
            <th>نسبة الواجبات</th>
            <th>نسبة الأنشطة والمشاريع</th>
            <th>نسبة الاختبارات</th>
            <th>نسبة تحقيق أهداف المسار الإفتراضي</th>
        </thead>
        <tbody>

        </tbody>
    </table>
  </div>
</template>

<script>
export default {
    data(){
        return {
            students: [],
            arr: [],
            custom_classname: false,
            classrooms: []
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        naqar_get("m_students_" + window.location.href.split("school_id=")[1]).then(function(r){
            g.students = r != 'undefined' && r != '' && r && r != 'null' ? JSON.parse(r) : []
            g.students.forEach(function(a){
                if(!g.classrooms.includes(a.classroom)){
                    g.classrooms.push(a.classroom)
                }
            })
            naqar_get("students_report").then(function(r){
                g.arr = JSON.parse(r)
                g.gg()
            })
        })
    },
    methods: {
        gg(){
            var g = this;
            $("tbody").html(' ');
            $.each(g.arr, function (i, student) {
                if(!g.custom_classname){
                    $("tbody").append(`
                    <tr>
                        <td>` + student.name + `</td>
                        <td>` + g.classroom(student.name) + `</td>
                        <td>` + student.percent_1 + `</td>
                        <td>` + student.percent_2 + `</td>
                        <td>` + student.percent_3 + `</td>
                        <td>` + student.percent_4 + `</td>
                    </tr>
                    `);
                }else{
                    if(g.custom_classname == g.classroom(student.name)){
                        $("tbody").append(`
                        <tr>
                            <td>` + student.name + `</td>
                            <td>` + g.classroom(student.name) + `</td>
                            <td>` + student.percent_1 + `</td>
                            <td>` + student.percent_2 + `</td>
                            <td>` + student.percent_3 + `</td>
                            <td>` + student.percent_4 + `</td>
                        </tr>
                        `);
                    }
                }
            });
        },
        print(){
            window.print()
        },
        classroom(name){
            var g = this;
            var percent = 0.5, nn = {}
            g.students.forEach(function(a){
                if(g.similarity(a.name, name) >= percent){
                    percent = g.similarity(a.name, name)
                    nn = a
                }
            })
            if(nn.name){
                return nn.classroom
            }
        },
        editDistance: (s1, s2) => {
            s1 = s1.toLowerCase();
            s2 = s2.toLowerCase();

            var costs = new Array();
            for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                costs[j] = j;
                else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1))
                    newValue = Math.min(Math.min(newValue, lastValue),
                        costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
            }
            return costs[s2.length];
        },
        similarity: function(s1, s2){
            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength == 0) {
            return 1.0;
            }
            return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
        },
    }
}
</script>

<style>

        * {
            white-space: nowrap;
        }

        table,
        td,
        th {
            border: 1px solid black;
            padding: 3px;
            white-space: nowrap;
        }

        table {
            width: 99% !important;
            border-collapse: collapse;
            margin: 0px auto;
        }

        th.r {
            border-top: 1px solid black;
        }

        td {
            padding-right: 15px;
            padding-left: 10px;
        }

        th {
            position: relative;
        }

        th span {
            position: absolute;
            transform: rotate(-50deg);
            bottom: 15px;
            left: 0px;
            width: 100%;
            height: 100%;
            direction: ltr;
            border-top: 1px solid black;
            border-radius: 7px 0px 0px 0px;
        }
@media print{
    .dnone2{
        display:none
    }
}
</style>